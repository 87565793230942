import React from "react";

const TagFilter = ({ currentTag, setTag }) => {
  const handleTagChange = (e) => {
    setTag(e.target.value);
  };

  return (
    <div className="tag-filter">
      <label htmlFor="tag-filter">Filter by tag:</label>
      <select
        id="tag-filter"
        name="tag-filter"
        value={currentTag}
        onChange={handleTagChange}
      >
        <option value="all">All</option>
        <option value="fashion">Fashion</option>
        <option value="commercial">Commercial</option>
        <option value="campaign">Campaign</option>
        <option value="teaser">Teaser</option>
      </select>
    </div>
  );
};

export default TagFilter;
