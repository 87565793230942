import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useMediaQuery } from "@material-ui/core";
import React, { Fragment } from "react";

const Navbar = ({ setTag }) => {
  const [active, setActive] = useState(false);
  const isWideScreen = useMediaQuery("(min-width: 760px)");
  const [curPath, setCurPath] = useState("/");
  const [isSticky, setIsSticky] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const handleSiteTitleClick = () => {
    setCurPath("/");
    setTag("all");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setCurPath(location.pathname);
    if (
      location.pathname === "/Contact" ||
      location.pathname === "/About" ||
      /^\/video\/\d+$/.test(location.pathname)
    ) {
      setIsSticky(true);
    }
  }, [location.pathname]);

  const handleScroll = () => {
    const buffer = 45;
    if (window.scrollY > 50 + buffer) {
      setIsSticky(true);
    } else if (window.scrollY < 50 - buffer) {
      if (
        !(
          curPath === "/Contact" ||
          curPath === "/About" ||
          /^\/video\/\d+$/.test(curPath)
        )
      ) {
        setIsSticky(false);
      }
    }
  };

  const debounce = (func, wait) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  };

  const handleResize = debounce(() => {
    if (window.innerWidth >= 760) {
      setActive(false);
    }
  }, 250);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, [curPath]);

  const toggleMenu = (event) => {
    event.preventDefault();
    if (!isWideScreen) {
      setActive(!active);
    }
  };

  const menuItems = [
    { name: "fashion", label: "FASHION" },
    { name: "commercial", label: "COMMERCIAL" },
    { name: "campaign", label: "CAMPAIGN" },
    { name: "teaser", label: "TEASER" },
  ];

  return (
    <nav className={`nav${isSticky ? " sticky" : ""}`}>
      <Link
        to="/"
        className="site-title"
        onClick={() => handleSiteTitleClick()}
      >
        <span
          onClick={() => {
            setTag("all");
          }}
          className="logo-font"
        >
          SOMDEFINITION
        </span>
      </Link>
      <a href="#" className="toggle-button" onClick={(e) => toggleMenu(e)}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </a>
      {(active || isWideScreen) && (
        <ul className="nav-links">
          <div className="dropdown">
            <CustomLink
              to="/"
              toggleMenu={toggleMenu}
              curPath={curPath}
              setCurPath={setCurPath}
            >
              <span
                onClick={() => {
                  setTag("all");
                }}
                className="navbar-font"
              >
                Works
              </span>
            </CustomLink>
            <ul className="dropdown-menu">
              {menuItems.map((item, index) => (
                <React.Fragment key={index}>
                  <li>
                    <span
                      style={{ cursor: "pointer" }}
                      className="navbar-font"
                      onClick={() => {
                        setTag(item.name);
                        navigate("/");
                      }}
                    >
                      {item.label}
                    </span>
                  </li>
                  {index !== menuItems.length - 1 && (
                    <li className="separator" draggable="false">
                      <span>|</span>
                    </li>
                  )}
                </React.Fragment>
              ))}
            </ul>
          </div>

          <CustomLink
            to="/About"
            toggleMenu={toggleMenu}
            curPath={curPath}
            setCurPath={setCurPath}
          >
            <span
              onClick={() => {
                setTag("all");
              }}
              className="navbar-font"
            >
              About
            </span>
          </CustomLink>
          <CustomLink
            to="/Contact"
            toggleMenu={toggleMenu}
            curPath={curPath}
            setCurPath={setCurPath}
          >
            <span
              onClick={() => {
                setTag("all");
              }}
              className="navbar-font"
            >
              Contact
            </span>
          </CustomLink>
        </ul>
      )}
    </nav>
  );
};

const CustomLink = ({
  to,
  children,
  toggleMenu,
  curPath,
  setCurPath,
  ...props
}) => {
  const isActive = curPath === to;
  return (
    <li className={isActive ? "active" : ""}>
      <Link
        to={to}
        {...props}
        className={"nav-link" + (curPath === to ? " isCurPath" : "")}
        onClick={() => {
          toggleMenu();
        }}
      >
        {children}
      </Link>
    </li>
  );
};

export default Navbar;
