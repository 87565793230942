import React, { useEffect, useRef, useState } from "react";
import videoData from "../data/videos";
import { Link } from "react-router-dom";
import "../styles/Works.css";
import { motion, AnimatePresence } from "framer-motion";
import InfiniteScroll from "react-infinite-scroll-component";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const useIntersectionObserver = (elementRef, options) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    }, options);

    const element = elementRef.current;

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [elementRef, options]);

  return isVisible;
};

const TagSelector = ({ curTag, handleTagClick }) => {
  return (
    <div className="tag-selector">
      <button
        onClick={() => handleTagClick("all")}
        className={curTag === "all" ? "active" : ""}
      >
        ALL
      </button>
      <button
        onClick={() => handleTagClick("fashion")}
        className={curTag === "fashion" ? "active" : ""}
      >
        FASHION
      </button>
      <button
        onClick={() => handleTagClick("commercial")}
        className={curTag === "commercial" ? "active" : ""}
      >
        COMMERCIAL
      </button>
      <button
        onClick={() => handleTagClick("campaign")}
        className={curTag === "campaign" ? "active" : ""}
      >
        CAMPAIGN
      </button>
      <button
        onClick={() => handleTagClick("teaser")}
        className={curTag === "teaser" ? "active" : ""}
      >
        TEASER
      </button>
    </div>
  );
};

const sortVideosByTagOrder = (videos, tagOrder) => {
  const orderedVideos = tagOrder
    .map((id) => videos.find((video) => video._id === id))
    .filter((video) => video !== undefined);

  const remainingVideos = videos.filter(
    (video) => !tagOrder.includes(video._id)
  );

  return [...orderedVideos, ...remainingVideos];
};

async function fetchVideos(tag) {
  // console.log(`Fetching videos for tag: ${tag}`);
  const API_BASE_URL = "https://port-0-somdef-node-5o1j2llh1kwsv1.sel4.cloudtype.app";
  try {
    // Fetch videos for the specified tag
    const response = await fetch(`${API_BASE_URL}/api/videos?tag=${tag}`);
    const videos = await response.json();

    // Fetch the TagOrder document for the specified tag
    const tagOrderResponse = await fetch(
      `${API_BASE_URL}/api/tags/order?tag=${tag}`
    );
    const tagOrder = await tagOrderResponse.json();
    // console.log(`Fetched tag order for tag ${tag}:`, tagOrder);

    // Sort the videos according to the tagOrder
    const orderedVideos = sortVideosByTagOrder(videos, tagOrder.order);
    // console.log(`Ordered videos for tag ${tag}:`, orderedVideos);

    return orderedVideos;
  } catch (error) {
    console.error("Error fetching videos:", error);
    return [];
  }
}

const Works = ({ curTag, setTag }) => {
  const scrollableDivRef = useRef(null);
  const [videos, setVideos] = useState([]);
  const [thumbnailsVisible, setThumbnailsVisible] = useState(true);
  const [loadedVideos, setLoadedVideos] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const scrollToTopBtnRef = useRef(null);

  useEffect(() => {
    fetchVideos(curTag).then((fetchedVideos) => {
      setVideos(fetchedVideos);
      setLoadedVideos(fetchedVideos.slice(0, 12));
      setHasMore(fetchedVideos.length > 12)
    });
  }, [curTag]);

  const loadMore = () => {
    if (loadedVideos.length >= videos.length) {
      setHasMore(false);
      return;
    }

    const newLoadedVideos = [
      ...loadedVideos,
      ...videos.slice(loadedVideos.length, loadedVideos.length + 6),
    ];
    setLoadedVideos(newLoadedVideos);
  };

  const handleTagClick = (tag) => {
    setThumbnailsVisible(false);
    setTimeout(() => {
      setTag(tag);
      setThumbnailsVisible(true);
    }, 500);
  };

  useEffect(() => {
    setupScrollToTopButton(scrollToTopBtnRef);
  }, []);

  return (
    <motion.div
      id="scrollableDiv"
      ref={scrollableDivRef}
      className="works-container"
      initial={{ opacity: 0, transition: { duration: 0.1 } }}
      animate={{ opacity: 1, transition: { duration: 0.1 } }}
    >
      <TagSelector curTag={curTag} handleTagClick={handleTagClick} />

      <InfiniteScroll
        key={curTag}
        className="infiniteScroll"
        dataLength={loadedVideos.length}
        next={loadMore}
        hasMore={hasMore}
      >
        <AnimatePresence>
          {loadedVideos.map((video, index) => (
            <Thumbnail
              key={index}
              video={video}
              index={index}
              visible={thumbnailsVisible}
              videoCount={loadedVideos.length}
            />
          ))}
        </AnimatePresence>
      </InfiniteScroll>

      <button ref={scrollToTopBtnRef} className="scroll-to-top-btn"></button>
    </motion.div>
  );
};

const Thumbnail = ({ video, visible, curTag, videoCount }) => {
  const thumbnailRef = useRef(null);
  const isVisible = useIntersectionObserver(thumbnailRef, { threshold: 0.1 });
  const thumbnailOpacity = isVisible && visible ? 1 : 0;
  const oneVideoClass = videoCount === 1 ? " one-video" : "";

  return (
    <motion.div
      ref={thumbnailRef}
      key={`${video.id}-${curTag}`}
      initial={{ opacity: 0, transition: { duration: 0.5 } }}
      animate={{ opacity: thumbnailOpacity, transition: { duration: 0.5 } }}
      className={`video-container${oneVideoClass}`}
    >
      <Link to={`/video/${video._id}`}>
        <LazyLoadImage
          src={video.thumbnail}
          srcSet={`
    ${video.thumbnail} 
  `}
          alt={video.title}
          className="video"
          effect="blur"
          placeholderSrc={video.thumbnail_l}
          loading="lazy"
        />

        <div className="overlay">
          <div className="content">
            <p>{video.title}</p>
          </div>
        </div>
      </Link>
    </motion.div>
  );
};

const setupScrollToTopButton = (scrollToTopBtnRef) => {
  const scrollToTopBtn = scrollToTopBtnRef.current;

  const handleScroll = () => {
    if (window.pageYOffset > 50) {
      scrollToTopBtn.style.display = "block";
    } else {
      scrollToTopBtn.style.display = "none";
    }
  };

  const handleButtonClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", handleScroll);
  scrollToTopBtn.addEventListener("click", handleButtonClick);

  return () => {
    window.removeEventListener("scroll", handleScroll);
    scrollToTopBtn.removeEventListener("click", handleButtonClick);
  };
};

export default Works;
