/* eslint-disable jsx-a11y/iframe-has-title */
import { useState, useEffect } from "react";
import videoData from "./../data/videos";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import "../styles/Video.css";


const calculateWidth = () => {
  const windowWidth = window.innerWidth;
  let marginPercentage;

  if (windowWidth <= 767) {
    marginPercentage = 10;
  } else if (windowWidth <= 991) {
    marginPercentage = 20;
  } else if (windowWidth <= 1199) {
    marginPercentage = 25;
  } else if (windowWidth <= 1500) {
    marginPercentage = 33;
  } else {
    marginPercentage = 33;
  }
  return (windowWidth * (100 - marginPercentage * 1)) / 100;
};

const Video = () => {
  const { videoId } = useParams();
  const [video, setVideo] = useState(null);
  const [tagOrder, setTagOrder] = useState([]);

  async function fetchVideoDetails(videoId) {
    const API_BASE_URL = "https://port-0-somdef-node-5o1j2llh1kwsv1.sel4.cloudtype.app";
    try {
      // console.log("fetching video")
      const response = await fetch(`${API_BASE_URL}/api/videos/${videoId}`);
      const videoData = await response.json();
      setVideo(videoData);
      // console.log(videoData);
    } catch (error) {
      console.error("Error fetching video details:", error);
    }
  }
  

  useEffect(() => {
    // console.log("Current video ID:", videoId);
    fetchVideoDetails(videoId);
  }, [videoId]);

  useEffect(() => {
    async function fetchTagOrder() {
      const API_BASE_URL = "https://port-0-somdef-node-5o1j2llh1kwsv1.sel4.cloudtype.app";
      try {
        const response = await fetch(`${API_BASE_URL}/api/tags/order?tag=all`);
        const orderData = await response.json();
        // console.log("Tag order:", orderData.order);
        setTagOrder(orderData.order);
      } catch (error) {
        console.error("Error fetching tag order:", error);
      }
    }

    fetchTagOrder();
  }, []);

  const currentIndex = tagOrder.indexOf(videoId);
  const previousVideoId =
    currentIndex === 0
      ? tagOrder[tagOrder.length - 1]
      : tagOrder[currentIndex - 1] || "0";
  const nextVideoId =
    currentIndex === tagOrder.length - 1
      ? tagOrder[0]
      : tagOrder[currentIndex + 1] || "0";

  const [width, setWidth] = useState(calculateWidth());

  const [playButtonClicked, setPlayButtonClicked] = useState(false);

  const handlePlayButtonClick = () => {
    setPlayButtonClicked(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(calculateWidth());
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // console.log(video.thumbnail);

  if (!video) {
    // Show a loading indicator while the video is being fetched
    return <div>Loading...</div>;
  }

  return (
    <div className="container" style={{ width: `${width}px` }}>
      {!playButtonClicked && (
        <div className="thumbnail-overlay" onClick={handlePlayButtonClick}>
          <img
            src={`${video.thumbnail}`}
            alt={video.title}
            className="thumbnail-image"
            style={{
              width: `${width}px`,
              height: `${(width * 0.5625).toFixed(0)}px`,
            }}
          />
          <img
            src="/images/icon/playbutton.png"
            alt="Play"
            className="play-button"
          />
        </div>
      )}
      <iframe
        className="video-player"
        src={
          playButtonClicked
            ? `${video.video}&title=0&byline=0&portrait=0&autoplay=1`
            : ""
        }
        width={`${width}px`}
        height={`${(width * 0.5625).toFixed(0)}px`}
        frameBorder="0"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen
        playsInline
        autoPlay
      ></iframe>
      <script src="https://player.vimeo.com/api/player.js"></script>

      <div className="video-text">
        <div className="video-details">
          <p>{video.title}</p>
        </div>
        <div
          className="video-switcher"
          style={{
            width: `${width}px`,
          }}
        >
          <Link to={`/video/${previousVideoId}`}>Prev</Link>
          <span className="video-switcher-separator">/</span>
          <Link to={`/video/${nextVideoId}`}>Next</Link>
        </div>
      </div>
    </div>
  );
};

export default Video;
