import { useState, useEffect } from "react";

const useAuth = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  
  const isAuthenticated = !!token;
  useEffect(() => {
    if (isAuthenticated) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }
  }, [isAuthenticated, token]);

  const login = (newToken) => {
    setToken(newToken);
  };

  const logout = () => {
    console.log("Removing token...");
    setToken(null);
    console.log("Token removed.");
  };

  return { token, isAuthenticated, login, logout };
};

export default useAuth;
