import React from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Works from "./../pages/Works";
import About from "./../pages/About";
import Contact from "./../pages/Contact";
import Video from "./../pages/Video";
import ContactResult from "./../pages/ContactResult";
import { AnimatePresence } from "framer-motion";
import Login from "./../pages/Login";
import AdminPanel from "./../pages/AdminPanel";
import useAuth from "./../auth/useAuth";

const AnimatedRoutes = ({ curTag, setTag }) => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Works setTag={setTag} curTag={curTag} />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/video/:videoId" element={<Video />} />
        <Route path="/contact/result" element={<ContactResult />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<AdminPanel />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
