import { motion, useAnimation } from "framer-motion";
import Layout from "./../components/Layout";
import { useEffect } from "react";
import "../styles/ContactResult.css";

const ContactResult = () => {
  const firstSentenceControls = useAnimation();
  const secondSentenceControls = useAnimation();

  useEffect(() => {
    const sequence = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await firstSentenceControls.start({
        opacity: 1,
        transition: { duration: 1.0 },
      });
      await secondSentenceControls.start({
        opacity: 1,
        transition: { duration: 1.0 },
      });
    };

    sequence();
  }, [firstSentenceControls, secondSentenceControls]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.4 } }}
      exit={{ opacity: 0, transition: { duration: 0.2 } }}
    >
      <Layout>
        <div className="result-message">
          <motion.div className="message" style={{ opacity: 0 }} animate={firstSentenceControls}>
            Thank you for your message.
          </motion.div>
          <motion.div className="message" style={{ opacity: 0 }} animate={secondSentenceControls}>
            We appreciate your interest and will respond to you shortly.
          </motion.div>
        </div>
      </Layout>
    </motion.div>
  );
};

export default ContactResult;
