import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import React from "react";
import Navbar from "./Navbar";
import AnimatedRoutes from './AnimatedRoutes';
import "../styles/App.css"
import {useState} from "react"

function App() {
  const [curTag, setTag] = useState("all")
  return (
    <>
      <Navbar setTag={setTag}/>
      <div className="main-container">
      <DndProvider backend={HTML5Backend}>
        <AnimatedRoutes setTag={setTag} curTag={curTag}/>
        </DndProvider>
      </div>
    </>
  );
}

export default App;
