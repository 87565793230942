import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes";

const DraggableVideoCard = ({ video, onEdit, onDelete, moveCard, index }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD, // <-- Change this line
    item: () => {
      return { id: video._id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    hover(item, monitor) {
      if (item.id === video._id) return;

      moveCard(item.id, index);
      item.index = index;
    },
  });

  const opacity = isDragging ? 0.5 : 1;

  return (
    <div className="card" ref={(node) => drag(drop(node))} style={{ opacity }}>
      <div
        className="video-thumbnail"
        style={{ backgroundImage: `url(${video.thumbnail})` }}
      ></div>
      <h3>{video.title}</h3>
      <p className="video-tag">Tag: {video.tag}</p>
      <div className="admin-buttons">
        <button className="edit-button admin-button" onClick={onEdit}>
          Edit
        </button>

        <button
          className="delete-button admin-button"
          onClick={() => onDelete()}
        >
          Delete
        </button>
      </div>
    </div>
  );
};

export default DraggableVideoCard;
