import { motion } from "framer-motion";
import React, {useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import Layout from "./../components/Layout";
import "../styles/Contact.css";
import { Link, useNavigate } from "react-router-dom";


const Contact = () => {
  const form = useRef();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, // Use env variable here
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID, // Use env variable here
        form.current,
        process.env.REACT_APP_EMAILJS_USER_ID // Use env variable here
      )
      .then(
        (result) => {
          console.log(result.text);
          navigate("/contact/result");
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      )
      .finally(() => setIsSubmitting(false));
  };
  

  return (
    <motion.div
      intial={{ opacity: 0, transition: { duration: 0.2 } }}
      animate={{ opacity: 1, transition: { duration: 0.4 } }}
      exit={{ opacity: 0, transition: { duration: 0.2 } }}
    >
      <Layout>
        <div className="contact-container">
          <form ref={form} onSubmit={sendEmail}>
            <div className="field">
              <label className="contact-label" htmlFor="from_name">
                What's your name?
              </label>
              <br />
              <input
                type="text"
                className="input"
                name="from_name"
                id="from_name"
                required
              />
            </div>
            <div className="field">
              <label className="contact-label" htmlFor="email_id">
                Email
              </label>
              <br />
              <input
                type="email"
                className="input"
                name="email_id"
                id="email_id"
                required
              />
            </div>

            <div className="field">
              <label className="contact-label" htmlFor="message">
                Message
              </label>
              <br />
              <textarea
                className="input"
                name="message"
                id="message"
                rows="3"
                required
              ></textarea>
            </div>
            {isSubmitting ? (
              <div className="loading">Submitting...</div>
            ) : (
              <input
                type="submit"
                className="submit-btn"
                value=""
                disabled={isSubmitting}
              />
            )}
          </form>
        </div>
      </Layout>
    </motion.div>
  );
};

export default Contact;
