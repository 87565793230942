import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import "../styles/About.css";
import Layout from "./../components/Layout";

const About = () => {
  const [about, setAbout] = useState("");
  useEffect(() => {
    const fetchAbout = async () => {
      const API_BASE_URL =
        "https://port-0-somdef-node-5o1j2llh1kwsv1.sel4.cloudtype.app";
      try {
        const response = await fetch(`${API_BASE_URL}/api/about`);
        const data = await response.json();
        setAbout(data.about);
      } catch (error) {
        console.error("Error fetching about:", error);
      }
    };

    fetchAbout();
  }, []);

  return (
    <motion.div
      intial={{ opacity: 0, transition: { duration: 0.2 } }}
      animate={{ opacity: 1, transition: { duration: 0.4 } }}
      exit={{ opacity: 0, transition: { duration: 0.2 } }}
    >
      <Layout>
        <div className="about-content">{about}</div>
      </Layout>
    </motion.div>
  );
};

export default About;
