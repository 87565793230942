import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, Navigate } from "react-router-dom";
import useAuth from "./../auth/useAuth";
import "../styles/AdminPanel.css";
import TagFilter from "./../components/TagFilter";
import DraggableVideoCard from "./../components/DraggableVideoCard";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { DropzoneDialog } from "material-ui-dropzone";
import { SHA1, enc } from "crypto-js";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  TextField,
} from "@material-ui/core";

// Configure cloudinary
const cloudinaryConfig = {
  cloud_name: process.env.REACT_APP_CLOUDINARY_CLOUD_NAME,
  api_key: process.env.REACT_APP_CLOUDINARY_API_KEY,
  api_secret: process.env.REACT_APP_CLOUDINARY_API_SECRET,
};

const AdminPanel = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  const [currentTag, setCurrentTag] = useState("all");
  const [tagOrder, setTagOrder] = useState({});
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editVideoData, setEditVideoData] = useState(null);

  const [videos, setVideos] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [dropzoneOpen, setDropzoneOpen] = useState(false);
  const [about, setAbout] = useState("");
  const [aboutModalOpen, setAboutModalOpen] = useState(false);

  const handleImageUpload = () => {
    setDropzoneOpen(true);
  };

  const getAuthHeader = () => {
    const token = localStorage.getItem("token");
    return { Authorization: `Bearer ${token}` };
  };

  useEffect(() => {
    fetchVideos();
    fetchTagOrder();
  }, [currentTag]); // Add currentTag as a dependency

  const [filteredVideos, setFilteredVideos] = useState([]);

  useEffect(() => {
    setFilteredVideos(
      videos
        .filter((video) => currentTag === "all" || currentTag === video.tag)
        .sort((a, b) => {
          if (tagOrder[currentTag] && tagOrder[currentTag].order) {
            const indexA = tagOrder[currentTag].order.indexOf(a._id);
            const indexB = tagOrder[currentTag].order.indexOf(b._id);

            if (indexA !== -1 && indexB !== -1) {
              return indexA - indexB;
            }
          }
          return 0;
        })
    );
  }, [videos, currentTag, tagOrder]);

  const API_BASE_URL =
    "https://port-0-somdef-node-5o1j2llh1kwsv1.sel4.cloudtype.app";

  const fetchTagOrder = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/tags/order`, {
        headers: getAuthHeader(),
        params: { tag: currentTag }, // Add the tag parameter
      });
      setTagOrder((prevTagOrder) => ({
        ...prevTagOrder,
        [currentTag]: response.data,
      }));
    } catch (error) {
      console.error("Error fetching tag order:", error);
    }
  };

  const fetchVideos = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/videos`);
      setVideos(response.data);
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  const handleLogout = () => {
    logout();
    setTimeout(() => {
      navigate("/login");
    }, 100);
  };

  const [title, setTitle] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [tag, setTag] = useState("");
  const [imagePreview, setImagePreview] = useState(null);
  const [imageFileName, setImageFileName] = useState("");

  const uploadToCloudinary = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "pjlclmhv");
    formData.append("api_key", cloudinaryConfig.api_key); // Add this line

    const response = await axios.post(
      `https://api.cloudinary.com/v1_1/${cloudinaryConfig.cloud_name}/image/upload`,
      formData
    );

    return response.data.secure_url;
  };

  const fileToDataUrl = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        resolve(event.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const handleDropzoneClose = () => {
    setDropzoneOpen(false);
  };

  const handleDropzoneSave = async (files) => {
    setImageFile(files[0]);
    setImageFileName(files[0].name);
    setDropzoneOpen(false);

    try {
      const dataUrl = await fileToDataUrl(files[0]);
      const uploadedImageUrl = await uploadToCloudinary(files[0]);
      console.log("Uploaded Image URL:", uploadedImageUrl); // Add this line
      setThumbnailUrl(uploadedImageUrl);
      setImagePreview(dataUrl);
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleAdd = async (event) => {
    event.preventDefault();
    let uploadedThumbnailUrl = "";

    if (imageFile) {
      try {
        uploadedThumbnailUrl = thumbnailUrl;
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }

    try {
      if (!title || !videoUrl || !uploadedThumbnailUrl || !tag) {
        console.error("All fields are required.");
        return;
      }

      const newVideoData = {
        title,
        video: videoUrl,
        thumbnail: uploadedThumbnailUrl,
        tag,
      };

      const response = await axios.post(
        `${API_BASE_URL}/api/videos`,
        newVideoData,
        { headers: getAuthHeader() }
      );

      if (tagOrder["all"]) {
        const newAllTagOrder = {
          ...tagOrder["all"],
          order: [...tagOrder["all"].order, response.data._id],
        };

        setTagOrder((prevTagOrder) => ({
          ...prevTagOrder,
          all: newAllTagOrder,
        }));

        // Update tag orders in the backend
        updateTagOrder(["all"], newAllTagOrder.order);
      }

      if (tag === currentTag) {
        const newTagOrder = {
          ...tagOrder[currentTag],
          order: [...tagOrder[currentTag].order, response.data._id],
        };

        setTagOrder((prevTagOrder) => ({
          ...prevTagOrder,
          [currentTag]: newTagOrder,
        }));

        // Update tag orders in the backend
        updateTagOrder([currentTag], newTagOrder.order);
      }

      setVideos([...videos, response.data]);
      setTitle("");
      setVideoUrl("");
      setThumbnailUrl("");
      setTag("");
      window.location.reload();
    } catch (error) {
      console.error("Error adding video:", error);
    }
  };

  const handleDelete = async (video) => {
    try {
      await axios.delete(`${API_BASE_URL}/api/videos/${video._id}`, {
        headers: getAuthHeader(),
      });

      const updatedVideos = videos.filter((v) => v._id !== video._id);
      setVideos(updatedVideos);

      // Update the tag order for the selected tag
      if (video.tag === currentTag && tagOrder[currentTag]) {
        const newTagOrder = {
          ...tagOrder[currentTag],
          order: tagOrder[currentTag].order.filter((id) => id !== video._id),
        };

        setTagOrder((prevTagOrder) => ({
          ...prevTagOrder,
          [currentTag]: newTagOrder,
        }));
      }

      if (tagOrder["all"]) {
        const newAllTagOrder = {
          ...tagOrder["all"],
          order: tagOrder["all"].order.filter((id) => id !== video._id),
        };

        setTagOrder((prevTagOrder) => ({
          ...prevTagOrder,
          all: newAllTagOrder,
        }));

        // Update tag orders in the backend
        updateTagOrder(["all"], newAllTagOrder.order);
      }

      if (video.tag === currentTag && tagOrder[currentTag]) {
        const newTagOrder = {
          ...tagOrder[currentTag],
          order: tagOrder[currentTag].order.filter((id) => id !== video._id),
        };

        setTagOrder((prevTagOrder) => ({
          ...prevTagOrder,
          [currentTag]: newTagOrder,
        }));

        // Update tag orders in the backend
        updateTagOrder([currentTag], newTagOrder.order);
      }
    } catch (error) {
      console.error("Error deleting video:", error);
    }
  };

  const openEditModal = (video) => {
    setTitle(video.title);
    setVideoUrl(video.video);
    setThumbnailUrl(video.thumbnail);
    setTag(video.tag);
    setEditVideoData(video);
    setEditModalOpen(true);
    setImageFile(null); // Reset image file
    setImageFileName(""); // Reset image file name
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
    setEditVideoData(null);
    setTitle("");
    setVideoUrl("");
    setThumbnailUrl("");
    setTag("");
    setImageFile(null); // If you want to reset the image file as well
    setImageFileName(""); // If you want to reset the image file name as well
  };

  const handleEditSave = async (event) => {
    event.preventDefault();

    try {
      const updatedVideoData = {
        ...editVideoData,
        title,
        video: videoUrl,
        thumbnail: thumbnailUrl, // Use the new URL
        tag,
      };

      
      const response = await axios.put(
        `${API_BASE_URL}/api/videos/${editVideoData._id}`,
        updatedVideoData,
        { headers: getAuthHeader() }
      );

      setVideos(
        videos.map((v) => (v._id === editVideoData._id ? response.data : v))
      );

      closeEditModal();
      window.location.reload();
    } catch (error) {
      console.error("Error editing video:", error);
    }
  };

  const handleAboutSave = async () => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/api/about`,
        {
          content: about, // assuming 'about' here contains the new 'About' content
        },
        { headers: getAuthHeader() }
      );

      // Set the about state to the updated value.
      setAbout(response.data.about.content);
    } catch (error) {
      console.error("Error updating about:", error);
    }
    setAboutModalOpen(false);
  };

  useEffect(() => {
    const fetchAbout = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/api/about`, {
          headers: getAuthHeader(),
        });
        setAbout(response.data.about);
      } catch (error) {
        console.error("Error fetching about:", error);
      }
    };

    fetchAbout();
  }, []);

  const resetOrder = () => {
    setVideos(videos.slice().sort((a, b) => a._id.localeCompare(b._id)));
  };

  const updateTagOrder = async (tag, orderedIds) => {
    try {
      const response = await axios.put(
        `${API_BASE_URL}/api/tagorders/update-order`,
        {
          tag,
          orderedIds,
        },
        { headers: getAuthHeader() }
      );

      console.log("Tag order updated:", response.data);
    } catch (error) {
      console.error("Error updating tag order:", error);
    }
  };

  const saveOrder = async () => {
    const orderedIds = filteredVideos.map((video) => video._id);

    try {
      const response = await axios.put(
        `${API_BASE_URL}/api/tagorders/update-order`,
        {
          tag: currentTag,
          orderedIds,
        },
        { headers: getAuthHeader() }
      );

      // Update the tagOrder state variable
      setTagOrder({ ...tagOrder, [currentTag]: orderedIds });
      window.location.reload();
    } catch (error) {
      console.error("Error updating order:", error);
    }
  };

  const moveCard = (draggedId, newIndex) => {
    const draggedIndex = filteredVideos.findIndex(
      (video) => video._id === draggedId
    );
    const newFilteredVideos = [...filteredVideos];

    const [draggedCard] = newFilteredVideos.splice(draggedIndex, 1);
    newFilteredVideos.splice(newIndex, 0, draggedCard);
    setFilteredVideos(newFilteredVideos);
  };

  return isAuthenticated ? (
    <div className="admin-container">
      <h1 className="admin-panel-title">Content Manager</h1>
      <button className="logout-button" onClick={handleLogout}>
        Logout
      </button>
      <button
        className="edit-about-button"
        onClick={() => setAboutModalOpen(true)}
      >
        Edit About
      </button>

      <div className="order-button-container">
        <button className="order-button" onClick={resetOrder}>
          Reset Order
        </button>
        <button className="order-button" onClick={saveOrder}>
          Save Order
        </button>
      </div>

      <TagFilter currentTag={currentTag} setTag={setCurrentTag} />
      <DndProvider backend={HTML5Backend}>
        <div className="video-row">
          {filteredVideos.map((video, index) => (
            <DraggableVideoCard
              key={video._id}
              video={video}
              onEdit={() => openEditModal(video)}
              onDelete={() => handleDelete(video)}
              moveCard={moveCard}
              index={index}
            />
          ))}
        </div>
      </DndProvider>
      <form className="add-video" onSubmit={handleAdd}>
        <h1>Add Video</h1>
        <div className="form-group">
          <label htmlFor="title">Title:</label>
          <input
            type="text"
            id="title"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="video">Video URL:</label>
          <input
            type="text"
            id="video"
            name="video"
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="thumbnail">Thumbnail:</label>
          <button type="button" onClick={handleImageUpload}>
            Upload Thumbnail
          </button>
        </div>
        {imageFileName && <span>({imageFileName})</span>}

        {imagePreview && (
          <div className="image-preview">
            <img
              className="small-image-preview"
              src={imagePreview}
              alt="Thumbnail preview"
            />
          </div>
        )}
        <div className="form-group">
          <label htmlFor="tag">Tag:</label>
          <select
            id="tag"
            name="tag"
            value={tag}
            onChange={(e) => setTag(e.target.value)}
          >
            <option value="">Select a tag</option>
            <option value="fashion">Fashion</option>
            <option value="commercial">Commercial</option>
            <option value="teaser">Teaser</option>
          </select>
        </div>
        <button
          type="submit"
          disabled={!title || !videoUrl || !thumbnailUrl || !tag}
        >
          Add Video
        </button>
      </form>
      <Dialog open={aboutModalOpen} onClose={() => setAboutModalOpen(false)}>
        <DialogTitle>Edit About</DialogTitle>
        <DialogContent>
          <TextField
            multiline
            rows={20} // This increases the height
            style={{ width: "500px" }} // This makes the TextField take up the full width of its container
            value={about}
            onChange={(event) => setAbout(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAboutModalOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              handleAboutSave();
              setAboutModalOpen(false);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <DropzoneDialog
        open={dropzoneOpen}
        onSave={handleDropzoneSave}
        acceptedFiles={["image/*"]}
        showPreviews={true}
        maxFileSize={5000000}
        onClose={handleDropzoneClose}
      />

      <Dialog open={editModalOpen} onClose={closeEditModal}>
        <DialogTitle>Edit Video</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill out the form below to update the video information.
          </DialogContentText>
          <form className="edit-video">
            <div className="form-group">
              <label htmlFor="edit-title">Title:</label>
              <input
                type="text"
                id="edit-title"
                name="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="edit-video">Video URL:</label>
              <input
                type="text"
                id="edit-video"
                name="video"
                value={videoUrl}
                onChange={(e) => setVideoUrl(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="edit-thumbnail">Thumbnail:</label>
              <button type="button" onClick={handleImageUpload}>
                Upload Thumbnail
              </button>
            </div>
            {imageFileName && <span>({imageFileName})</span>}
            {imagePreview ? (
              <div className="image-preview">
                <img
                  className="small-image-preview"
                  src={imagePreview}
                  alt="Thumbnail preview"
                />
              </div>
            ) : (
              <div className="image-preview">
                <img
                  className="small-image-preview"
                  src={thumbnailUrl}
                  alt="Thumbnail preview"
                />
              </div>
            )}

            <div className="form-group">
              <label htmlFor="edit-tag">Tag:</label>
              <select
                id="edit-tag"
                name="tag"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
              >
                <option value="">Select a tag</option>
                <option value="fashion">Fashion</option>
                <option value="commercial">Commercial</option>
                <option value="teaser">Teaser</option>
              </select>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEditModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default AdminPanel;
